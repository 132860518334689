<template>
  <el-dropdown
    class="float-right"
    trigger="hover"
    @command="changeLocale"
  >
    <el-button
      type="text"
      class="el-dropdown-link"
    >
      <flag :iso="current_lang.flag" />
      {{ current_lang.title }}
      <i class="el-icon-arrow-down el-icon--right" />
    </el-button>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        v-for="entry in lang"
        :key="entry.title"
        :command="entry.language"
      >
        <flag :iso="entry.flag" />
        {{ entry.title }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
    import i18n, {languge_list} from "@/lang/lang_config";
    import {arrayFind} from "@/commons/utils/functions";

    export default {
        name: "PzLanguage",
        components: {},
        data() {
            return {
                lang: languge_list,
                locale: i18n.locale,
                current_lang: {
                    flag: this.locale,
                    language: this.locale,
                    title: '',
                },
            };
        },
        created() {
            this.setFlag(this.locale);
        },
        methods: {
            changeLocale(command) {
                this.$store.dispatch('setLocale', command)
                this.setFlag(command);
            },
            setFlag(command) {
                this.current_lang = arrayFind(this.lang, (elem) => (elem.language) === command);
            }
        },
    }
</script>

<style lang="scss">


</style>